<template>
  <div class="row">
    <v-biscuit-detail
      :show-modal="showModalBiscuitUpdate"
      v-on:closemodal="closemodal"
      :edit="edit"
      :biscuit-details="biscuitDetail"
    ></v-biscuit-detail>
    <div class="col-md-12" style="background: white">
      <table class="table table-striped">
        <thead>
          <tr>
            <th width="20%">
              <input
                type="text"
                autocomplete="false"
                v-model="searchInput"
                class="form-control"
                placeholder="Search Biscuit Name"
              />
            </th>
            <th colspan="3">
              <button
                class="btn btn-success mr-3"
                style="float: right"
                @click="openmodel"
              >
                <i class="fa fa-plus"></i>
                Add Biscuit
              </button>
            </th>
          </tr>

          <tr>
            <th>SN</th>
            <th>Name</th>
            <th style="width: 21%"></th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && filteredBiscuits.length > 0">
          <tr v-for="(item, index) in filteredBiscuits" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ item.name }}</td>
            <td>
              <button
                @click="openBiscuitUpdateModal(item)"
                class="btn btn-sm btn-primary mr-3"
              >
                Edit
              </button>

              <button
                @click="deleteItem(item.id)"
                class="btn btn-sm btn-danger mr-3"
                style="cursor: pointer"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!loadingData && filteredBiscuits.length < 1">
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>

        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_BISCUITS_LISTS,
  DELETE_BISCUITS_DETAIL,
} from "@/core/services/store/actions.type";
import vBiscuitDetail from "@/components/frontend/modal/biscuitdetail";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },

        {
          id: 2,
          title: "Biscuits",
          route: "",
        },
      ],
      loadingData: false,
      searchInput: "",
      biscuits: [],
      showModalBiscuitUpdate: false,
      biscuitDetail: {},
      edit: true,
      location_id: "",
    };
  },
  components: {
    vDataLoader,
    vBiscuitDetail,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    filteredBiscuits() {
      let tempUser = this.biscuits;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempUser = tempUser.filter((item) => {
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempUser;
    },
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getBiscuitData();
  },
  methods: {
    closemodal(status) {
      this.showModalBiscuitUpdate = false;
      if (status) {
        this.getBiscuitData();
      }
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this User data!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_BISCUITS_DETAIL, id).then((data) => {
            this.$toastr.s(data.msg);
            // this.filteredBiscuits.splice(index, 1);
            this.getBiscuitData();
          });
        }
      });
    },
    openmodel() {
      this.biscuitDetail = {
        name: "",
      };
      this.showModalBiscuitUpdate = true;
      this.edit = false;
    },
    openBiscuitUpdateModal(data) {
      this.biscuitDetail = { ...data };
      this.showModalBiscuitUpdate = true;
      this.edit = true;
    },

    loadingButton(ref) {
      const submitButton = this.$refs[ref];

      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },

    getBiscuitData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_BISCUITS_LISTS)
        .then((data) => {
          this.biscuits = data;
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
  },
};
</script>

<style></style>
