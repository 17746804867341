<style lang="scss">
#app-user-detail {
  .close {
    display: none;
  }
}
</style>
<template>
  <b-modal
    id="app-user-detail"
    no-close-on-backdrop
    centered
    v-model="showModal"
    :title="edit ? 'Update Biscuit Detail' : 'Add Biscuit'"
  >
    <div class="form-group">
      <label for="">Name <span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        v-model="biscuitDetails.name"
        :class="errors['name'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['name']" class="text-danger">{{
        errors["name"][0]
      }}</span>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-left"
        >
          Close
        </b-button>

        <b-button
          v-if="edit"
          ref="kt_update_user"
          variant="success"
          size="sm"
          class="float-right"
          @click="updateBiscuit()"
        >
          Update
        </b-button>
        <b-button
          v-else
          ref="kt_store_user"
          variant="success"
          size="sm"
          class="float-right"
          @click="storeBiscuit()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import { ADD_BISCUITS_DETAIL } from "@/core/services/store/actions.type";
// import { throws } from "assert";
export default {
  data() {
    return {
      errors: [],
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    biscuitDetails: {
      type: Object,
      default: function () {
        return [];
      },
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    close() {
      this.errors = [];
      this.$emit("closemodal", 0);
    },
    storeBiscuit() {
      const submitButton = this.$refs["kt_store_user"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.errors = [];
      this.$store
        .dispatch(ADD_BISCUITS_DETAIL, {
          id: 0,
          name: this.biscuitDetails.name,
        })
        .then((data) => {
          this.$toastr.s(data.msg);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$emit("closemodal", 1);
        })
        .catch((err) => {
          this.errors = err.msg;
          // console.log(err);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
    },
    updateBiscuit() {
      const submitButton = this.$refs["kt_update_user"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.errors = [];
      this.$store
        .dispatch(ADD_BISCUITS_DETAIL, {
          id: this.biscuitDetails.id,
          name: this.biscuitDetails.name,
        })
        .then((data) => {
          this.$toastr.s(data.msg);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$emit("closemodal", 1);
        })
        .catch((err) => {
          this.errors = err.msg;
          // console.log(err);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
    },
  },
};
</script>
